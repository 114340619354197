import { APIS } from '~constants/index';
import { useLocalStorage, type UserStorage } from '~hooks/index';
import { sendRequest } from '~utils/index';

export const updateUserStorage = () => {
  const { updateUserData } = useLocalStorage();

  sendRequest<UserStorage>({
    url: APIS.MANAGE_USERS.USERS,
    thenFunction: async (res) => {
      if (res.status === 200) {
        updateUserData(res.data);
        await getSidebarData();
      }
    }
  });
};

export const getSidebarData = async () => {
  const { updateUserData } = useLocalStorage();

  await sendRequest({
    url: APIS.CORE.NAV_BAR,
    thenFunction: (res) => {
      if (res.status === 200) {
        updateUserData({ sidebarData: res.data });
        window.location.reload();
      } else updateUserData({ sidebarData: [] });
    },
    catchFunction: () => updateUserData({ sidebarData: [] })
  });
};
