import { isArray, pickBy } from 'lodash';

export type ArrayOptionsType = (string | number | null | undefined)[];

const isAllDataNull = (arrayData: ArrayOptionsType) => {
  return arrayData.every(
    (data) => data === null || data === undefined || data === ''
  );
};

export const verifyData = (arrayData: ArrayOptionsType, separator = ' ') => {
  if (isAllDataNull(arrayData)) return ' - ';
  else return arrayData.join(separator);
};

export const cleanObject = (data: object | []): object =>
  pickBy(data, (value: object | []) => {
    if (isArray(value) && value?.length !== 0) return true;
    if (!isArray(value) && !!value) return true;
    return false;
  });

export const cleanArray = (data: ArrayOptionsType) =>
  data.filter((item) => item != null);

export const cleanArrayOfObjects = (data: object[]) =>
  data?.filter((obj) => Object.values(obj).every((item) => !!item));
