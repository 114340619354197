export default [
  {
    pk: 1,
    page_name: 'Riesgo',
    indicators: [
      {
        name: 'Riesgo',
        uuid: 'e7087cae-b4bc-479e-95bb-3776e7413c11'
      }
    ]
  },
  {
    pk: 2,
    page_name: 'Tamizaje',
    indicators: [
      {
        name: 'Tamizaje',
        uuid: '5710bc5f-cbc3-4e23-b3b6-4b5c6b74f354'
      }
    ]
  },
  {
    pk: 3,
    page_name: 'Oncología',
    indicators: [
      {
        name: 'Oncología',
        uuid: '6730287b-022b-4f9d-bffb-8d127f490f25'
      }
    ]
  },
  {
    pk: 4,
    page_name: 'Unindad funcional 4',
    indicators: [
      {
        name: 'Oncología',
        uuid: '6730287b-022b-4f9d-bffb-8d127f490f25'
      }
    ]
  },
  {
    pk: 5,
    page_name: 'Unindad funcional 5',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 6,
    page_name: 'Unindad funcional 6',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 7,
    page_name: 'Unindad funcional 7',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 8,
    page_name: 'Unindad funcional 8',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 9,
    page_name: 'Unindad funcional 9',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 10,
    page_name: 'Unindad funcional 10',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 11,
    page_name: 'Unindad funcional 11',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 12,
    page_name: 'Unindad funcional 12',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 13,
    page_name: 'Unindad funcional 13',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 14,
    page_name: 'Unindad funcional 14',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 15,
    page_name: 'Unindad funcional 15',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 16,
    page_name: 'Tamizaje mamografías',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 17,
    page_name: 'Tamizaje PSA',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 18,
    page_name: 'Tamizaje Sangre Oculta',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 19,
    page_name: 'Tamizaje Cervix',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 21,
    page_name: 'Oseo',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 22,
    page_name: 'Torax',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 23,
    page_name: 'Abdomen',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 24,
    page_name: 'Leucemias',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 25,
    page_name: 'Histocitosis',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 26,
    page_name: 'SNC',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 27,
    page_name: 'Linfomas',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 28,
    page_name: 'Tejidos blandos',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 30,
    page_name: 'Mama',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 31,
    page_name: 'Cervix',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 32,
    page_name: 'Prostata',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 33,
    page_name: 'Colon y recto',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 34,
    page_name: 'Estomago',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 35,
    page_name: 'Linfoma no hodking',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 36,
    page_name: 'Melanomas',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 37,
    page_name: 'Sarcomas',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 38,
    page_name: 'Germinal',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 39,
    page_name: 'Leucemias',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 40,
    page_name: 'Cabeza y cuello',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 41,
    page_name: 'No priorizados',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  }
] as WorkUnit[];
