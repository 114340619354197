import Button, { type ButtonProps } from './Button';

export interface ButtonListProps extends ButtonProps {
  isHidden?: boolean;
}

interface Props {
  data: ButtonListProps[];
}

const ButtonsList = ({ data }: Props) => (
  <div className="d-flex flex-wrap gap-2">
    {data
      .filter((btn) => !btn.isHidden)
      .map((btn, index) => (
        <Button key={`button_${index}`} type={btn.type ?? 'button'} {...btn} />
      ))}
  </div>
);

export default ButtonsList;
