import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import useLocalStorage from '~hooks/useLocalStorage';
import {
  ErrorCode,
  ErrorDataType,
  ErrorType,
  useAppStorage
} from '~store/useStore';
import { refreshTokens } from './ErrorBoundaryBackRequests';
import './errorBoundaryBack.scss';

const ErrorBoundaryBack = ({ children }: React.PropsWithChildren) => {
  const { t } = useTranslation();
  const { appName, userData, updateUserData } = useLocalStorage();
  const { errorRequest, cleanError } = useAppStorage();

  const validateError = (error: ErrorType) => {
    //* Check for token expiration
    const errorCode = error as ErrorCode;
    if (errorCode?.code) {
      if (errorCode.code === 'token_not_valid') {
        refreshTokens(userData.refresh, clearAppStorage, updateUserData);
      }
    }
    //* Show toast if error is not an HTML
    else {
      if (typeof error !== 'string') {
        const errors = Object.values(error);
        errors.map((singularError: string) => toast.error(t(singularError)));
      } else toast.error(t('toasts.request_error'));
      cleanError();
    }
    return error;
  };

  useEffect(() => {
    if (errorRequest) {
      const errorDataRequest = errorRequest as ErrorDataType;
      if (errorDataRequest?.data) {
        validateError(errorDataRequest.data);
      } else {
        validateError(errorRequest);
      }
    }
  }, [errorRequest]);

  const clearAppStorage = () => {
    cleanError();
    localStorage.removeItem(appName);
    window.location.replace('/');
  };

  return children;
};

export default ErrorBoundaryBack;
