import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useLocalStorage } from '~hooks/index';
import { UserAuthenticated } from '~layouts/index';
import { ROUTES } from './index';

const PrivateRoute = () => {
  const { userData, recaptcha, removeRecaptcha } = useLocalStorage();

  useEffect(() => {
    if (recaptcha) removeRecaptcha();
  }, []);

  return (
    <>
      {Object.keys(userData).length > 2 ? (
        <UserAuthenticated>
          <Outlet />
        </UserAuthenticated>
      ) : (
        <Navigate to={ROUTES.ROOT} />
      )}
    </>
  );
};

export default PrivateRoute;
