export default [
  {
    pk: 1,
    page_name: 'Encuesta calidad de vida',
    indicators: [
      /* {
        name: 'Encuesta calidad de vida',
        uuid: ''
      } */
    ]
  },
] as WorkUnit[];
