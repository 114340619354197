import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CustomIcon } from '~components/index';
import {
  ErrorBoundaryFront,
  InactivityObserver,
  TicsocialWatermark
} from '~layouts/index';
import Sidebar from '../Sidebar/Sidebar';
import './userAuthenticated.scss';

const UserAuthenticated = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();

  const windowWidth = window.innerWidth;
  const [collapse, setCollapse] = useState(windowWidth < 1024);

  useEffect(() => {
    if (windowWidth < 1024) setCollapse(true);
  }, [location.pathname]);

  return (
    <InactivityObserver>
      <div className="userAuthenticated m-0 p-0">
        <TicsocialWatermark />
        <div
          className={`userAuthenticated_collapse_btn ${
            !collapse ? 'userAuthenticated_collapse_btn_sidebar_visible' : ''
          }`}
          onClick={() => setCollapse(!collapse)}
        >
          <CustomIcon name={!collapse ? 'chevronLeft' : 'app_logo'} />
        </div>
        <Sidebar collapse={collapse} />
        <ErrorBoundaryFront>
          <div className="userAuthenticated_bg">
            <div className="userAuthenticated_content">{children}</div>
          </div>
        </ErrorBoundaryFront>
      </div>
    </InactivityObserver>
  );
};

export default UserAuthenticated;
