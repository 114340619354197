import { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { BasicModal, Button, CustomIcon } from '~components/index';
import { ROUTES } from '~constants/index';
import { useLocalStorage } from '~hooks/index';
import { logout } from '~layouts/ErrorBoundaries/ErrorBoundaryBack/ErrorBoundaryBackRequests';
import { sidebarItemsRender } from './SidaberHandler';
import { updateUserStorage } from './SidebarRequests';
import './sidebar.scss';

interface Props {
  collapse: boolean;
}
const Sidebar = ({ collapse }: Props) => {
  const defaultLanguage = import.meta.env.VITE_DEFAULT_LANGUAGE;
  const { t } = useTranslation();
  const location = useLocation();
  const { appName, userData, appImplementation, setLanguage, getLanguage } = useLocalStorage();
  const [showModal, setShowModal] = useState('none');

  const clearAppStorage = () => {
    localStorage.removeItem(appName);
    window.location.replace('/');
    setLanguage(defaultLanguage)
  };

  const availableModals: Record<string, JSX.Element> = {
    deployModal: (
      <BasicModal
        title="btns.releases"
        body={
          <>
            <div className="d-flex flex-column gap-2">
              <ul>
                <li>Agregar modal de notas de despliegue</li>
              </ul>
            </div>
            <div className="d-flex flex-column gap-2">
              <p>
                <b>Version:</b> 2.0.2
              </p>
              <p>
                <b>Rama:</b> RF109
              </p>
            </div>
          </>
        }
        onHide={() => setShowModal('none')}
      />
    )
  };

  return (
    <>
      {showModal !== 'none' && availableModals[showModal]}
      <div
        className={`sidebar ${collapse ? 'sidebar_hidden' : 'sidebar_visible'}`}
      >
        <div className="sidebar_content">
          <NavLink to={ROUTES.PLATFORM} className="sidebar_logo_container">
            <Image
              src={appImplementation?.logos?.logo_inverse}
              alt="app_logo"
              className="sidebar_logo"
            />
          </NavLink>
          {userData.is_superuser && (
            <Button
              variant="link"
              iconName="releases"
              title="btns.releases"
              onClick={() => setShowModal('deployModal')}
            />
          )}
          {sidebarItemsRender(userData.sidebarData, location, getLanguage)}
          <div className="sidebar_userOptions">
            <CustomIcon
              name="user"
              color="var(--sidebar-icon-color)"
              size="LG"
            />
            <div className="sidebar_userLogged">
              <div className="d-flex gap-2 align-items-center">
                {userData?.first_name ?? ' - '}
                <div title={t('btns.update_user')} onClick={updateUserStorage}>
                  <CustomIcon
                    name="reload"
                    color="var(--sidebar-text-color)"
                    size="XS"
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <Button
                variant="link"
                iconName="logout"
                text="btns.logout"
                onClick={() => logout(userData.refresh, clearAppStorage)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
