export default [
  {
    pk: 1,
    page_name: 'Ruta incans pulmón',
    indicators: [
      {
        name: 'Pulmón',
        uuid: '3696cb05-8179-4f94-a3ee-39ac8968b306'
      }
    ]
  },
  {
    pk: 2,
    page_name: 'Tamizaje pulmón',
    indicators: [
      {
        name: 'Pulmón',
        uuid: '51cf6039-f1db-4e3d-954a-deb05b59aa7f'
      }
    ]
  },
] as WorkUnit[];
