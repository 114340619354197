import { tnl } from '~i18n/i18n';

export const REMOVE_FILTER_SELECT = {
  label: 'components.input_select_remove_filter',
  value: ''
};

export const EMPTY_SELECT = {
  label: 'components.input_select_empty',
  value: ' '
};

export const GENDER = [
  { label: tnl('MALE'), value: 'M' },
  { label: tnl('FEMALE'), value: 'F' },
  { label: tnl('UNDEFINED'), value: 'U' }
];

export * from './apis';
export * from './appRoutes';
export * from './inputRules';
export * from './permissions';
