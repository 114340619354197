import AES from 'crypto-js/aes';
import UTF8 from 'crypto-js/enc-utf8';

import { LanguageCode } from '~layouts/Internationalization/Internationalization';
import type { SidebarData } from '~layouts/Sidebar/SidebarTypes';
import type { AppImplementation } from './useImplementation';

export interface UserStorage {
  access: string;
  email: string;
  first_name: string;
  last_name: string;
  permissions: string[];
  pk: number;
  refresh: string;
  sidebarData: SidebarData[];
  tac: unknown;
  username: string;
  is_superuser: boolean;
}

type Store = UserStorage | AppImplementation;

const useLocalStorage = () => {
  const appName = 'karya_platform';
  const implementation = 'karya_implementation';

  const encryptUserData = (data: Partial<Store>) => {
    const dataEncrypted = AES.encrypt(
      JSON.stringify(data),
      import.meta.env.VITE_ENCRYPTION_KEY
    ).toString();
    return dataEncrypted;
  };
  const decryptUserData = (data: string): Partial<Store> => {
    let userDataDecrypted = {};
    if (data !== null) {
      const bytes = AES.decrypt(data, import.meta.env.VITE_ENCRYPTION_KEY);
      userDataDecrypted = JSON.parse(bytes.toString(UTF8)) as string;
    }
    return userDataDecrypted;
  };

  const userData = decryptUserData(
    localStorage.getItem(appName)!
  ) as UserStorage;
  const userPermissions = userData?.permissions ?? [];
  const userAccess = userData?.access;

  const updateUserData = (newUserData = {}) => {
    const prevUserData = decryptUserData(localStorage.getItem(appName)!) || {};
    const updatedUserData = { ...prevUserData, ...newUserData };
    localStorage.setItem(appName, encryptUserData(updatedUserData));
  };

  const recaptcha = localStorage.getItem('recaptcha') ?? '';
  const setRecaptcha = (token: string) => {
    localStorage.setItem('recaptcha', JSON.stringify(token));
  };
  const removeRecaptcha = () => localStorage.removeItem('recaptcha');

  const appImplementation = decryptUserData(
    localStorage.getItem(implementation)!
  ) as AppImplementation;
  const updateAppImplementation = (newImplementationData = {}) => {
    localStorage.setItem(
      implementation,
      encryptUserData(newImplementationData)
    );
  };

  const setLanguage = (language: string) => {
    localStorage.setItem('language', language);
  };

  const getLanguage = (): LanguageCode => {
    return (
      (localStorage.getItem('language') as LanguageCode) ??
      import.meta.env.VITE_DEFAULT_LANGUAGE
    );
  };

  return {
    appName,
    userData,
    userAccess,
    userPermissions,
    recaptcha,
    appImplementation,
    setRecaptcha,
    removeRecaptcha,
    updateUserData,
    updateAppImplementation,
    getLanguage,
    setLanguage
  };
};

export default useLocalStorage;
