import i18next from 'i18next';

import useLocalStorage from '~hooks/useLocalStorage';
import back_en from './en/back.json';
import front_en from './en/front.json';
import back_es from './es/back.json';
import front_es from './es/front.json';
import back_por from './por/back.json';
import front_por from './por/front.json';

const resources = {
  es: {
    allTranslations: { ...front_es, ...back_es }
  },
  en: {
    allTranslations: { ...front_en, ...back_en }
  },
  por: {
    allTranslations: { ...front_por, ...back_por }
  }
};

const { getLanguage } = useLocalStorage();

i18next.init({
  interpolation: { escapeValue: false },
  lng: getLanguage(),
  resources,
  ns: ['allTranslations']
});

export const tnl = i18next.t;
export default i18next;
