export default {
  '--app-primary-color': 'hsl(221, 100%, 18%)',
  '--app-secondary-color': 'hsl(221, 100%, 18%)',
  '--app-tertiary-color': 'hsl(221, 100%, 83%)',
  '--app-disabled-color': 'hsl(0, 0%, 82%)',
  '--sidebar-bg-color': 'hsl(0, 0%, 100%)',
  '--sidebar-icon-color': 'hsl(0, 0%, 0%)',
  '--sidebar-text-color': 'hsl(0, 0%, 0%)',
  '--sidebar-active-bg-color': 'hsl(208, 100%, 27%)',
  '--sidebar-active-text-color': 'hsl(0, 0%, 0%)'
};
