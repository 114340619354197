export default {
  '--app-primary-color': 'hsl(221, 100%, 18%)',
  '--app-secondary-color': 'hsl(221, 100%, 18%)',
  '--app-tertiary-color': 'hsl(221, 100%, 83%)',
  '--app-disabled-color': 'hsl(0, 0%, 82%)',
  '--sidebar-bg-color': 'hsl(221, 100%, 18%)',
  '--sidebar-icon-color': 'hsl(0, 0%, 100%)',
  '--sidebar-text-color': 'hsl(0, 0%, 100%)',
  '--sidebar-active-bg-color': 'hsl(342, 100%, 45%)',
  '--sidebar-active-text-color': 'hsl(0, 0%, 100%)'
};
