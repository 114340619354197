import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { useLocalStorage } from '~hooks/index';
import { ErrorBoundaryFront } from '~layouts/index';
import { ROUTES } from './index';

const PublicRoute = () => {
  const { userData, recaptcha, setRecaptcha } = useLocalStorage();

  return (
    <>
      {Object.keys(userData).length > 2 ? (
        <Navigate to={ROUTES.PLATFORM} />
      ) : (
        <ErrorBoundaryFront>
          <GoogleReCaptcha
            onVerify={(token) => {
              if (!recaptcha) setRecaptcha(token);
            }}
          />
          <Outlet />
        </ErrorBoundaryFront>
      )}
    </>
  );
};

export default PublicRoute;
