import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import { CustomIcon } from '~components/index';
import type { ActiveKey } from './Accordion';

interface Props<T> {
  item: T;
  index: number;
  activeKey: ActiveKey;
  onEnter?: (item: T) => void;
  setActiveKey: Setter<ActiveKey>;
}

export const AccordionToggle = <T extends object>({
  item,
  index,
  activeKey,
  setActiveKey,
  onEnter
}: Props<T>) => {
  const onClick = useAccordionButton(`${index}`, () => {
    if (activeKey !== `${index}`) {
      setActiveKey(`${index}`);
      onEnter && onEnter(item);
    } else setActiveKey(null);
  });

  return (
    <div role="accordion_toggle" onClick={onClick}>
      <CustomIcon
        name={`${index}` === activeKey ? 'chevronUp' : 'chevronDown'}
        className="cursor-pointer"
      />
    </div>
  );
};
