import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import En from '~assets/flag_en.svg';
import Es from '~assets/flag_es.svg';
import Por from '~assets/flag_por.svg';
import { useLocalStorage } from '~hooks/index';
import './Internationalization.scss';

export type LanguageCode = 'es' | 'en' | 'por';

const Internationalization = () => {
  const defaultLanguage = import.meta.env.VITE_DEFAULT_LANGUAGE as LanguageCode;
  const languageOptions = {
    es: { label: 'Español', icon: Es },
    en: { label: 'English', icon: En },
    por: { label: 'Português', icon: Por },
  };

  const { setLanguage, getLanguage } = useLocalStorage();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(languageOptions[defaultLanguage].label);
  const [selectedFlag, setSelectedFlag] = useState<string>(languageOptions[defaultLanguage].icon);

  useEffect(() => {
    const currentLanguage = getLanguage() || defaultLanguage;
    const { label, icon } = languageOptions[currentLanguage];
    setSelectedLanguage(label);
    setSelectedFlag(icon);
    i18next.changeLanguage(currentLanguage);
  }, []);

  const handleLanguageChange = (language: LanguageCode) => {
    const { label, icon } = languageOptions[language];
    setLanguage(language);
    setSelectedLanguage(label);
    setSelectedFlag(icon);
    window.location.reload();
  };

  const languageActions = [
    {
      label: languageOptions.es.label,
      onClick: () => handleLanguageChange('es'),
    },
    {
      label: languageOptions.en.label,
      onClick: () => handleLanguageChange('en'),
    },
    {
      label: languageOptions.por.label,
      onClick: () => handleLanguageChange('por'),
    },
  ];

  return (
    <div className="internationalization">
      <div className="d-flex align-items-center gap-2 me-3">
        <DropdownButton
          id="dropdown-language-selector"
          title={
            <div className="d-flex align-items-center gap-2">
              <img
                src={selectedFlag}
                alt="Language Icon"
                className="language-icon"
                style={{ width: '20px', height: '20px' }}
              />
              <span>{selectedLanguage}</span>
            </div>
          }
          drop="down"
          className="custom-dropdown-button"
        >
          {languageActions.map((language, index) => (
            <Dropdown.Item key={`language_${index}`} onClick={language.onClick}>
              {language.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    </div>
  );
};

export default Internationalization;