import axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse
} from 'axios';
import { omit } from 'lodash';

import { useLocalStorage } from '~hooks/index';
import { type ErrorType, useAppStorage } from '~store/useStore';
import { cleanObject } from '~utils/index';

export interface RequestParams {
  length?: number;
  page?: number;
  [key: string]: unknown;
}

export interface CatchError {
  response: { data: ErrorType };
}

interface SendRequestProps<T> {
  url: string;
  method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
  data?: object;
  params?: RequestParams;
  isDatatable?: boolean;
  thenFunction: (response: AxiosResponse<T>) => void;
  catchFunction?: (error: AxiosError) => void;
  finallyFunction?: () => void;
  type?: 'private' | 'public';
  extraConfig?: Record<string, string>;
}

const sendRequest = async <T>({
  url,
  method = 'get',
  data = {},
  params = {},
  isDatatable = false,
  extraConfig = {},
  thenFunction,
  catchFunction,
  finallyFunction,
  type = 'private'
}: SendRequestProps<T>) => {
  const { userAccess } = useLocalStorage();
  const { requestFinalized, saveUserError } = useAppStorage.getState();

  const token = {
    private: `Bearer ${userAccess}`,
    public: undefined
  }[type];

  let finalParams: RequestParams = params;
  if (isDatatable) {
    const length = params.length ?? 0;
    const page = params.page ?? 0;

    finalParams = omit(
      {
        format: 'datatables',
        length: params.length,
        start: length * page || 0,
        ...params
      },
      ['page']
    );
  }

  const recaptchaRaw = localStorage.getItem('recaptcha');
  const recaptchaParse: string = recaptchaRaw
    ? (JSON.parse(recaptchaRaw) as string)
    : '';
  const config: AxiosRequestConfig = cleanObject({
    baseURL: import.meta.env.VITE_BACKEND_URL,
    url,
    method,
    data,
    params: finalParams,
    headers: cleanObject({
      Authorization: token,
      recaptcha: recaptchaParse
    }),
    ...extraConfig
  });

  await axios(config)
    .then((response: AxiosResponse<T>) => thenFunction(response))
    .catch((error: AxiosError) => {
      const userError = error as CatchError;
      requestFinalized();
      saveUserError(userError.response?.data);
      catchFunction && catchFunction(error);
    })
    .finally(() => {
      finallyFunction && finallyFunction();
    });
};

export default sendRequest;
