import type { Location } from 'react-router-dom';

import { Accordion, CustomIcon } from '~components/index';
import { LanguageCode } from '~layouts/Internationalization/Internationalization';
import SidebarItem from './SidebarItem';
import { SidebarData, SidebarItemData } from './SidebarTypes';

export const getLocalizedLabel = (
  item: SidebarData | SidebarItemData,
  language: LanguageCode
) => {
  const labels = {
    es: item.label_es,
    en: item.label_en,
    por: item.label_por
  };

  return labels[language] || '';
};

const sidebarInfoHandler = (
  item: SidebarData,
  location: Location,
  getLanguage: () => LanguageCode,
  level: number
) => {
  const language = getLanguage();

  if (item.children.length === 0) {
    return (
      <div key={item.id} className={level > 0 ? 'sidebar_identation' : ''}>
        <SidebarItem
          data={item}
          location={location}
          getLanguage={getLanguage}
        />
      </div>
    );
  } else {
    return (
      <div
        key={`sidebar_parent_${item.id}`}
        className={level > 0 ? 'sidebar_identation' : ''}
      >
        <Accordion
          data={[item]}
          header={(item) => {
            return (
              <div className="d-flex align-items-center">
                <CustomIcon name={item.icon} />
                <p className="text-break ms-2">
                  {getLocalizedLabel(item, language)}
                </p>
              </div>
            );
          }}
          body={({ children }) => (
            <div className="d-flex flex-column gap-1 mt-1">
              {children.map((child) =>
                sidebarInfoHandler(child, location, getLanguage, level + 1)
              )}
            </div>
          )}
          type="light"
          isSidebar
        />
      </div>
    );
  }
};

export const sidebarItemsRender = (
  data: SidebarData[],
  location: Location,
  getLanguage: () => LanguageCode
) => (
  <div className="sidebar_items my-2">
    {data.map((item) => sidebarInfoHandler(item, location, getLanguage, 0))}
  </div>
);
