export default [
  {
    pk: 1,
    page_name: 'Trazabilidad',
    indicators: [
      { name: 'Trazabilidad', uuid: 'c01728c6-773f-4fac-acde-c31a3f878598' }
    ]
  },
  {
    pk: 2,
    page_name: 'Trazabilidad',
    indicators: [{
      name: 'Trazabilidad',
      uuid: '78755663-c81d-463c-81a8-5d8c146f85bf'
    }]
  },
  {
    pk: 3,
    page_name: 'Trazabilidad',
    indicators: [
      {
        name: 'Trazabilidad',
        uuid: 'b7069abb-871c-421c-af0b-689259c6494e'
      }
    ]
  },
  {
    pk: 4,
    page_name: 'Trazabilidad',
    indicators: [
      {
        name: 'Trazabilidad',
        uuid: '51a87bca-ef56-4732-89d9-9c70a23e3412'
      }
    ]
  },
  {
    pk: 5,
    page_name: 'Trazabilidad',
    indicators: [{
      name: 'Trazabilidad',
      uuid: '2480a5d6-263e-4b57-b334-59d3d892f43c'
    }]
  },
  {
    pk: 6,
    page_name: 'Trazabilidad',
    indicators: [{
      name: 'Trazabilidad',
      uuid: '0b590edf-8561-48c3-b787-dd18e9a055cc'
    }]
  },
  {
    pk: 25,
    page_name: 'Trazabilidad',
    indicators: [{
      name: 'Trazabilidad',
      uuid: '760fa246-9ed4-474f-8bbd-fc13ad841d02'
    }]
  },
  {
    pk: 27,
    page_name: 'Gestante ingreso',
    indicators: [
      { name: 'Ingreso', uuid: '78974dfc-2cdc-43e9-9711-45de9a0f8502' }
    ]
  },
  {
    pk: 28,
    page_name: 'Gestante 1',
    indicators: []
  },
  {
    pk: 29,
    page_name: 'Gestante 2',
    indicators: []
  },
  {
    pk: 30,
    page_name: 'Gestante 3',
    indicators: []
  },
  {
    pk: 31,
    page_name: 'Gestante - Evento obstétrico',
    indicators: []
  },
  {
    pk: 32,
    page_name: 'Gestante - Postnatal',
    indicators: []
  },
  {
    pk: 33,
    page_name: 'Gestante - Frecuencia',
    indicators: [
      { name: 'Gestante', uuid: 'c17e6109-c5a7-4a96-8191-e467982d76df' }
    ]
  },
  {
    pk: 34,
    page_name: 'Gestante - Hospitalización',
    indicators: []
  },
  {
    pk: 37,
    page_name: 'Hipertensión',
    indicators: [
      { name: 'Trazabilidad', uuid: '9feafb06-b5ad-48f0-a34c-45585f46dd5b' },
      { name: 'Alertas', uuid: '991b4c76-6465-4e21-889f-a8ca72aa7e9e' }
    ]
  },
  {
    pk: 39,
    page_name: 'Diabetes',
    indicators: [
      { name: 'Trazabilidad', uuid: 'da5f8f5a-746c-4733-8ce0-c40ce12801ea' },
      { name: 'Alertas', uuid: '73b8ff31-138d-423c-b5c1-4186c0a6ce0b' }
    ]
  },
  {
    pk: 40,
    page_name: 'Unidad funcional',
    indicators: []
  },
  {
    pk: 41,
    page_name: 'Pluripatológico',
    indicators: [
      {
        name: 'Pluripatológico 1',
        uuid: 'd1078b11-aaaa-4561-9671-1fba63659af0'
      },
      {
        name: 'Pluripatológico 1',
        uuid: 'd38cc64b-851a-44a5-b114-07af0c4744e2'
      }
    ]
  },
  {
    pk: 43,
    page_name: 'Generalidades',
    indicators: []
  },
  {
    pk: 44,
    page_name: 'Neonatos',
    indicators: []
  },

] as WorkUnit[];
