import { t } from 'i18next';
import { toast } from 'react-toastify';

import { APIS } from '~constants/index';
import type { CredentialTokens } from '~pages/Public/LoginPage/LoginRequests';
import type { ErrorDetail } from '~store/useStore';
import { sendRequest } from '~utils/index';
import type { CatchError } from '~utils/sendRequest';

export const logout = (refresh_token: string, clearAppStorage: () => void) => {
  sendRequest<unknown>({
    url: APIS.LOGOUT,
    method: 'post',
    type: 'public',
    data: { refresh_token },
    thenFunction: () => clearAppStorage(),
    catchFunction: (error) => {
      const errorObtained = error as CatchError;
      const errorDetail = errorObtained.response.data as ErrorDetail;
      if (errorDetail?.detail === 'Token is invalid or expired') {
        clearAppStorage();
      }
    }
  });
};

export const refreshTokens = (
  refresh: string,
  clearAppStorage: () => void,
  updateUserData: (newUserData?: object) => void
) => {
  sendRequest<CredentialTokens>({
    url: APIS.TOKEN_REFRESH,
    method: 'post',
    data: { refresh },
    thenFunction: (res) => {
      updateUserData(res.data);
      window.location.reload();
    },
    catchFunction: () => {
      toast.info(t('toasts.token_expired'));
      setTimeout(() => logout(refresh, clearAppStorage), 3000);
    }
  });
};
