import { useEffect, useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Sleeping from '~assets/sleeping.svg';
import { ROUTES } from '~constants/index';
import { useLocalStorage } from '~hooks/index';

const InactivityObserver = ({ children }: React.PropsWithChildren) => {
  const hours = Number(import.meta.env.VITE_INACTIVE_HOURS_LIMIT);
  const miliseconds = hours * 60 * 60 * 1000;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { appName } = useLocalStorage();
  const [isActive, setIsActive] = useState(true);
  const [inactiveTime, setInactiveTime] = useState(0);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let timer: NodeJS.Timeout;

    const clean = () => {
      clearTimeout(timeout);
      clearInterval(timer);
    };

    const handleActivity = () => {
      setIsActive(true);
      setInactiveTime(0);
      clean();
      timeout = setTimeout(() => {
        setIsActive(false);
      }, miliseconds);
    };

    const countInactiveTime = () => {
      setInactiveTime((prevTime) => prevTime + 1);
    };
    if (!isActive) {
      timer = setInterval(countInactiveTime, 1000);
    }

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);

    return () => {
      clean();
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
    };
  }, [inactiveTime, isActive]);

  useEffect(() => {
    if (inactiveTime > 60) {
      localStorage.removeItem(appName);
      navigate(ROUTES.LOGIN);
    }
  }, [inactiveTime]);

  return (
    <>
      {!isActive && (
        <Modal show backdrop="static" keyboard={false} size="lg">
          <Modal.Header>
            <h4>{t('labels.inactivity_detected_title')}</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="p-3 d-flex flex-column align-items-center justify-content-center">
              <Image src={Sleeping} alt="img_inactivity" width="30%" fluid />
              <div className="d-flex flex-column gap-2 px-3 align-items-center justify-content-center text-center mt-3">
                <p>{t('labels.inactivity_detected_desc')}</p>
                <div className="d-flex gap-2">
                  <p className="fw-bold">
                    {t('labels.inactivity_detected_time')}
                  </p>
                  <p>{inactiveTime}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {children}
    </>
  );
};

export default InactivityObserver;
