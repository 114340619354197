interface Props extends React.PropsWithChildren {
  disabled: boolean;
  link?: string;
}

const LinkButtonWrapper = ({ link, disabled = false, children }: Props) => {
  if (link)
    return (
      <a
        rel="noopener noreferrer"
        target="_blank"
        role="link_download"
        href={link}
        style={{
          cursor: disabled ? 'initial' : 'pointer',
          pointerEvents: disabled ? 'none' : 'initial'
        }}
        download
      >
        {children}
      </a>
    );
  else return children;
};

export default LinkButtonWrapper;
