import { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import EB_Front from '~assets/error_boundary_front.svg';
import { Button } from '~components/index';
import './errorBoundaryFront.scss';

interface FallbackError {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackError) => {
  const { t } = useTranslation();

  const stackTrace = error.stack ?? '';
  const stackLines = stackTrace.split('\n');
  const firstStackLine = stackLines[1] || '';

  // Extract file and line information from the stack trace
  const matchResult = /\s*at\s.*\((.*):(\d+):(\d+)\)/.exec(firstStackLine);

  const fileName = matchResult
    ? matchResult[1]
        .substring(matchResult[1].lastIndexOf('/') + 1)
        .split('?')[0]
    : 'Unknown file';

  const lineNumber = matchResult ? matchResult[2] : 'Unknown line';

  useEffect(() => {
    console.log({ message: error.message, fileName, lineNumber });
  }, []);

  return (
    <div className="eb_container">
      <div>
        <Image
          className="eb_container_image"
          src={EB_Front}
          alt="img_eb_front"
          fluid
        />
        <h4 className="fw-bold mt-3">
          {t('components.errorBoundary_front_title')}
        </h4>
      </div>
      <Button text="btns.app_reload" onClick={() => resetErrorBoundary()} />
    </div>
  );
};

const ErrorBoundaryFront = ({ children }: React.PropsWithChildren) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
};

export default ErrorBoundaryFront;
