import i18next from 'i18next';
import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useImplementation, useLocalStorage } from '~hooks/index';
import { ErrorBoundaryBack, ErrorBoundaryFront } from '~layouts/index';
import RoutesDirectory from '~routes/RoutesDirectory';
import './app.scss';

const App = () => {
  const implementation = useImplementation();
  const { appImplementation, updateAppImplementation } = useLocalStorage();

  useEffect(() => {
    updateAppImplementation(implementation);
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={appImplementation?.envs?.google_recaptcha}
    >
      <ToastContainer position="top-right" autoClose={5000} pauseOnHover />
      <I18nextProvider i18n={i18next}>
        <ErrorBoundaryBack>
          <ErrorBoundaryFront>
            <RoutesDirectory />
          </ErrorBoundaryFront>
        </ErrorBoundaryBack>
      </I18nextProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
