import { useEffect, useMemo, useState } from 'react';
import { Accordion as BootstrapAccordion } from 'react-bootstrap';

import { AccordionToggle } from './AccordionToggle';
import './accordion.scss';

export type ActiveKey = string | null | number;
interface Props<T> {
  type?: 'normal' | 'light';
  data: T[];
  header: (item: T) => React.ReactNode;
  body: (item: T) => React.ReactNode;
  isSidebar?: boolean;
  onEnter?: (item: T) => void;
}

const Accordion = <T extends object>({
  data = [],
  isSidebar = false,
  type = 'normal',
  header,
  body,
  onEnter
}: Props<T>) => {
  const [activeKey, setActiveKey] = useState<ActiveKey>(null);

  useEffect(() => {
    if (!isSidebar) setActiveKey(null);
  }, []);

  const style = useMemo(() => {
    let header = 'accordion_header';
    let body = 'accordion_body';
    if (type === 'light') {
      header = 'accordion_header_light';
      body = 'accordion_header_body';
    }
    return { header, body };
  }, [type]);

  return (
    <BootstrapAccordion activeKey={`${activeKey}`}>
      {data.map((item, index) => (
        <div key={index}>
          <div
            className={`${style.header}${
              `${index}` === activeKey ? '_active' : ''
            }`}
          >
            <div
              className={`d-flex align-items-center justify-content-between gap-3 w-100 ${
                isSidebar ? 'cursor-pointer' : ''
              }`}
              onClick={() => {
                if (isSidebar) {
                  if (activeKey !== `${index}`) {
                    setActiveKey(`${index}`);
                  } else setActiveKey(null);
                }
              }}
            >
              {header(item)}
              <AccordionToggle
                item={item}
                index={index}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                onEnter={onEnter}
              />
            </div>
          </div>
          <BootstrapAccordion.Collapse eventKey={`${index}`}>
            <div className={style.body}>{body(item)}</div>
          </BootstrapAccordion.Collapse>
        </div>
      ))}
    </BootstrapAccordion>
  );
};

export default Accordion;
