import HomeImg from '~assets/home.svg';
import AppLogo from './assets/app_logo.svg';
import AppLogoInverse from './assets/app_logo_inverse.svg';
import { Colors, WorkUnits } from './constants';

export const coosalud_settings: ImplementationSettings = {
  colors: Colors,
  envs: {
    google_recaptcha: import.meta.env.VITE_RECAPTCHA_KEY,
    backend_url: import.meta.env.VITE_BACKEND_URL
  },
  logos: {
    logo_regular: AppLogo,
    logo_inverse: AppLogoInverse,
    landing: HomeImg
  },
  workUnits: WorkUnits
};
