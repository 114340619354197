export default {
  '--app-primary-color': 'hsl(241, 99%, 68%)',
  '--app-secondary-color': 'hsl(241, 93%, 82%)',
  '--app-tertiary-color': 'hsl(241, 98%, 95%)',
  '--app-disabled-color': 'hsl(0, 0%, 89%)',
  '--sidebar-bg-color': 'hsl(0, 0%, 95%)',
  '--sidebar-icon-color': 'hsl(241, 99%, 68%)',
  '--sidebar-text-color': 'hsl(0, 0%, 13%)',
  '--sidebar-active-bg-color': 'hsl(241, 99%, 68%)',
  '--sidebar-active-text-color': 'hsl(0, 0%, 100%)'
};
