export default {
  '--app-primary-color': 'hsl(0, 0%, 45%)',
  '--app-secondary-color': 'hsl(215, 27%, 18%)',
  '--app-tertiary-color': 'hsl(133, 54%, 88%)',
  '--app-disabled-color': 'hsl(0, 0%, 89%)',
  '--sidebar-bg-color': 'hsl(0, 0%, 95%)',
  '--sidebar-icon-color': 'hsl(87, 39%, 50%)',
  '--sidebar-text-color': 'hsl(0, 0%, 13%)',
  '--sidebar-active-bg-color': 'hsl(87, 39%, 50%)',
  '--sidebar-active-text-color': 'hsl(0, 0%, 100%)'
};